import { Component } from 'react'
import { Container, Navbar } from 'react-bootstrap'

import './styles/headernavbar.css'
import SocialMedia from './SocialMedia'
import ContactButton from './ContactButton'

class HeaderNavbar extends Component {
  render() {
    return (
      <div data-testid="headerNavbar-component">
        <Navbar>
          <Container className="contanier">
            <SocialMedia cssClass="" />
            <ContactButton text="CONTATO" cssClass="contact-button header" />
          </Container>
        </Navbar>
      </div>
    )
  }
}

export default HeaderNavbar
