import { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import './styles/bio.css'

class Bio extends Component {
  render() {
    return (
      <div data-testid="bio-component" className="bio-component">
        <Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <h2>QUEM SOU EU</h2>
            </Col>
          </Row>
          <Row className="content">
            <Col sm={12} lg={6} xs={12}>
              <Row className="text">
                <p>
                  Olá! Eu sou Gabrielle, arquiteta e urbanista formada pela UFRN
                  e estou à frente do escritório. Sempre fui apaixonada por
                  criar e expressar através da arte os meus próprios sonhos, e
                  agora através da arquitetura busco dar vida aos sonhos das
                  pessoas ao meu redor.
                </p>
                <p>
                  A minha missão é conceber espaços com afeto e personalidade,
                  dando voz às minhas raízes potiguares através de uma
                  arquitetura contemporânea capaz de proporcionar bem-estar e
                  criar memórias. Considero que a arquitetura só é eficiente
                  quando conseguimos imprimir nela a essência de quem vai
                  habitar ou utilizar. Então entre os nossos valores, uma das
                  nossas maiores prioridades é compreender os seus sonhos e
                  desejos, e conhecer quem você é, para assim expressar a sua
                  personalidade no seu projeto.
                </p>
                <p>
                  Aliamos isso ao respeito à sua realidade financeira, nos
                  utilizando de processos organizados e eficientes, além de
                  muita atenção aos detalhes, para entregar produtos cativantes
                  e de qualidade.
                </p>
              </Row>
            </Col>
            <Col sm={8} lg={4} xs={8}>
              <img
                data-testid="bio-main-image"
                src={require('../assets/images/bio1.jpg')}
                alt="Foto principal da seção biografia"
              />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Bio
