import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'

import img1 from '../assets/images/project1.png'
import img2 from '../assets/images/project2.png'
import img3 from '../assets/images/project3.png'
import img4 from '../assets/images/project4.png'
import img5 from '../assets/images/project5.png'
import img6 from '../assets/images/project6.png'

import './styles/slider.css'

const images = [img1, img2, img3, img4, img5, img6]

function Slider() {
  const carousel = useRef<HTMLDivElement | null>(null)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    setWidth(carousel.current!.scrollWidth - carousel.current!.offsetWidth)
  }, [])

  return (
    carousel && (
      <div className="slider" data-testid="slider">
        <motion.div
          ref={carousel}
          className="carousel"
          whileTap={{ cursor: 'grabbing' }}
        >
          <motion.div
            drag="x"
            className="inner"
            dragConstraints={{ right: 0, left: -width }}
          >
            {images.map((image) => (
              <motion.div key={image} className="item">
                <img src={image} alt={`Text alt of ${image}`} />
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </div>
    )
  )
}

export default Slider
