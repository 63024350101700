import { Component } from 'react'

import Bio from '../components/Bio'
import Banner from '../components/Banner'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Projects from '../components/Projects'
import Services from '../components/Services'

class Home extends Component {
  render() {
    return (
      <div data-testid="home-page">
        <Header />
        <Banner />
        <Bio />
        <Projects />
        <Services />
        <Footer />
      </div>
    )
  }
}

export default Home
