import React from 'react'
import { Instagram } from 'react-bootstrap-icons'

interface ISocialMedia {
  href: string
  icon: React.ElementType
  id: string
}

export const socialMediaInstagram: ISocialMedia = {
  href: 'https://www.instagram.com/gabrielledelima.arquitetura/',
  icon: Instagram,
  id: 'instagram-icon',
}

export const socialMedias = [socialMediaInstagram]
