export const contact = {
  link: 'https://wa.me',
  number: '55084988625174',
  text: 'Ol%C3%A1%20Gabrielle%21%20Gostaria%20de%20or%C3%A7ar%20meu%20projeto%20com%20voc%C3%AA.%20Podemos%20conversar%3F',
}

const architectural = {
  title: 'PROJETO DE ARQUITETURA',
  description:
    'O projeto arquitetônico é para quem quer construir do zero ou reformar. Começa na criação primária dos espaços, considerando os condicionantes do local como ventilação, iluminação e características do terreno, buscando tirar o melhor proveito deles para conceber um projeto confortável, personalizado e funcional, atendendo a todas as necessidades do cliente.',
}

const settingArchitecture = {
  title: 'PROJETO DE INTERIORES',
  description:
    'Interiores é para quem já tem um espaço e quer ambientar. Consiste na concepção dos espaços internos em diferentes níveis: podem ser trabalhadas as disposições dos ambientes, os móveis, os revestimentos e acabamentos, peças de decoração e alterações nas instalações existentes (iluminação, tomadas, hidráulica), buscando sempre priorizar o conforto e proporcionar experiências que façam com que o cliente se conecte ao lugar.',
}

export const services = [architectural, settingArchitecture]
