import { Component } from 'react'

import './styles/header.css'
import HeaderNavbar from './HeaderNavbar'

class Header extends Component {
  render() {
    return (
      <div data-testid="header-component" className="header">
        <HeaderNavbar />
      </div>
    )
  }
}

export default Header
