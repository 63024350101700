import './styles/contactButton.css'
import { contact } from '../mocks/services'

interface IContactButton {
  text: string
  cssClass?: string
}

function ContactButton(props: IContactButton) {
  const { link, number, text } = contact

  return (
    <div className={props.cssClass!} data-testid="contact-button">
      <a
        target="_blank"
        rel="noreferrer"
        href={`${link}/${number}?text=${text}`}
      >
        <p>{props.text}</p>
      </a>
    </div>
  )
}

export default ContactButton
