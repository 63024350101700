import { Nav } from 'react-bootstrap'
import { socialMedias } from '../mocks/socialMedia'

import './styles/socialMedia.css'

interface ISocialMedia {
  cssClass: string
}

function SocialMedia(props: ISocialMedia) {
  return (
    <Nav data-testid="socialMedia-navigator" className={props.cssClass}>
      {socialMedias.map(({ href, id, icon: IconComponent }) => {
        return (
          <Nav.Link
            key={id}
            href={href}
            target="_blank"
            className="socialMedia-item"
          >
            <IconComponent data-testid={id} style={{ color: '#de9b45' }} />
          </Nav.Link>
        )
      })}
    </Nav>
  )
}

export default SocialMedia
