import { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import { PlusLg } from 'react-bootstrap-icons'

import './styles/projects.css'
import Slider from './Slider'
import { socialMediaInstagram } from '../mocks/socialMedia'

class Projects extends Component {
  render() {
    return (
      <div data-testid="projects-component" className="project-component">
        <Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <h2 data-testid="project-title">PROJETOS</h2>
            </Col>
          </Row>

          <Row>
            <Slider />
          </Row>

          <Row>
            <div
              className="more-projects-button"
              data-testid="more-projects-button"
            >
              <a
                target="_blank"
                rel="noreferrer"
                className="more-projects-link"
                href={socialMediaInstagram.href}
              >
                <PlusLg className="more-projects-icon" />
                VEJA MAIS PROJETOS
              </a>
            </div>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Projects
