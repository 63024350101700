import { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import './styles/services.css'
import { services } from '../mocks/services'

class Services extends Component {
  render() {
    return (
      <div data-testid="services" className="services">
        <Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <h2 data-testid="service-title">SERVIÇOS</h2>
            </Col>
          </Row>

          <Row>
            <div>
              {services.map(({ title, description }, i) => (
                <div
                  key={title}
                  className={`service ${
                    services.length !== i + 1 ? 'horizontal-bar' : ''
                  }`}
                >
                  <div className="service-position">
                    <p>{i + 1}</p>
                  </div>
                  <div className="service-title">
                    <p>{title}</p>
                  </div>
                  <div className="service-description">
                    <p>{description}</p>
                  </div>
                </div>
              ))}
            </div>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Services
