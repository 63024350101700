import { Container, Navbar } from 'react-bootstrap'

import './styles/footer.css'
import SocialMedia from './SocialMedia'
import ContactButton from './ContactButton'

function Footer() {
  return (
    <div>
      <Container className="footer" data-testid="footer">
        <ContactButton text="ORÇAR MEU PROJETO" cssClass="contact-button" />
        <Navbar>
          <SocialMedia cssClass="" />
        </Navbar>
      </Container>
    </div>
  )
}

export default Footer
