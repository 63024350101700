import 'bootstrap/dist/css/bootstrap.min.css'
import Home from './pages/Home'
import './app.css'

function App() {
  return (
    <div className="app">
      <Home />
    </div>
  )
}

export default App
