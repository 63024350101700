import { Component } from 'react'

import './styles/banner.css'

class Banner extends Component {
  render() {
    return (
      <div data-testid="banner-component">
        {/* <Container className="logo-container">
          <img
            className="logo"
            src={require('../assets/images/logo.png')}
            alt="Logo do escritório Gabrielle de Lima Arquitetura e Interiores"
          />
        </Container> */}
        <div className="banner-image">
          <img
            data-testid="banner-image"
            src={require('../assets/images/banner.png')}
            alt="Banner do escritório Gabrielle de Lima Arquitetura"
          />
        </div>
      </div>
    )
  }
}

export default Banner
